var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "Terms of Use",
      "persistent": "",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          isValid: _vm.isValid
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.isLoaded ? _c('div', [_vm._l(_vm.termsList, function (term) {
    return [term.code == 'privacy-agree' ? _c('page-section', {
      key: term._id,
      staticClass: "page-section--xxs pb-0 pt-0",
      scopedSlots: _vm._u([{
        key: "pageSectionImmersive",
        fn: function () {
          return [_c('div', {
            staticClass: "tit-wrap tit-wrap--small mb-10"
          }, [_c('div', {
            staticClass: "pb-8px pb-md-12px",
            staticStyle: {
              "width": "100%"
            }
          }, [_c('v-row', {
            staticClass: "row--sm"
          }, [_c('v-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('h3', {
            staticClass: "font-size-16 font-size-md-18 txt--dark font-weight-medium",
            staticStyle: {
              "line-height": "1.35"
            }
          }, [_vm._v(" " + _vm._s(term.subject) + "에 동의합니다. "), _c('span', {
            staticClass: "pl-4px",
            class: [term.necessary ? 'secondary--text' : 'grey--text text--lighten-1']
          }, [_vm._v("(" + _vm._s(term.necessary ? "필수" : "선택") + ")")])])]), _c('v-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('v-layout', {
            attrs: {
              "justify-end": ""
            }
          }, [_c('v-checkbox', {
            attrs: {
              "value": _vm.selected.includes(term._id),
              "label": "동의",
              "hide-details": ""
            },
            on: {
              "click": function ($event) {
                return _vm.toggleSelectedAll(term._id);
              }
            },
            model: {
              value: _vm.agreeTerm,
              callback: function ($$v) {
                _vm.agreeTerm = $$v;
              },
              expression: "agreeTerm"
            }
          })], 1)], 1)], 1)], 1)]), _c('v-card', {
            attrs: {
              "outlined": "",
              "tile": ""
            }
          }, [_c('div', {
            staticClass: "pa-16px pa-md-20px mh-120px",
            staticStyle: {
              "overflow-y": "auto"
            }
          }, [_c('div', {
            domProps: {
              "innerHTML": _vm._s(term.content.replace(/\n/gi, '<br/>'))
            }
          })])])];
        },
        proxy: true
      }], null, true)
    }) : _vm._e()];
  }), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_c('btn-tertiary', {
    attrs: {
      "block": "",
      "x-large": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")])], 1), _c('v-col', [_c('btn-secondary', {
    attrs: {
      "block": "",
      "x-large": ""
    },
    on: {
      "click": _vm.agree
    }
  }, [_vm._v("동의")])], 1)], 1)], 1)], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }