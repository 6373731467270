<template>
    <u-dialog v-model="shows" title="Terms of Use" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, isValid }" />
        </template>
        <div v-if="isLoaded">
            <!-- <tit-wrap-small>
                <div class="vw-100 w-sm-auto">
                    <v-checkbox v-model="isValid" label="I fully agree to the terms and conditions." readonly hide-details class="v-size--large" @click="toggleSelectedAll" />
                </div>
            </tit-wrap-small> -->
            <!-- <v-divider class="mt-8px" /> -->

            <!-- <template v-for="term in termsList">
                <page-section v-if="term.code == 'privacy-agree'" :key="term._id" class="page-section--xxs pb-0 pt-0">
                    <template #pageSectionImmersive>
                        <tit-wrap-small>
                            <template #titleContents>
                                I agree with {{ term.subject }}.<span class="pl-4px" :class="[term.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ term.necessary ? "required" : "optional" }})</span>
                            </template>
                            <div class="vw-100 w-sm-auto">
                                <v-checkbox v-model="selected" :value="term._id" label="Agree" hide-details @click="toggleSelectedAll"/>
                            </div>
                        </tit-wrap-small>
                        <v-card outlined tile>
                            <div class="pa-16px pa-md-20px mh-120px" style="overflow-y: auto;"><div v-html="term.content.replace(/\n/gi, '<br/>')" /></div>
                        </v-card>
                    </template>
                </page-section>
            </template> -->

            <template v-for="term in termsList">
                <page-section v-if="term.code == 'privacy-agree'" :key="term._id" class="page-section--xxs pb-0 pt-0">
                    <template #pageSectionImmersive>
                        <div class="tit-wrap tit-wrap--small mb-10">
                            <div class="pb-8px pb-md-12px" style="width: 100%;">
                                <v-row class="row--sm">
                                    <v-col cols="12">
                                        <h3 class="font-size-16 font-size-md-18 txt--dark font-weight-medium" style="line-height: 1.35">
                                            {{ term.subject }}에 동의합니다. <span class="pl-4px" :class="[term.necessary ? 'secondary--text' : 'grey--text text--lighten-1']">({{ term.necessary ? "필수" : "선택" }})</span>
                                        </h3>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-layout justify-end>
                                            <v-checkbox v-model="agreeTerm" :value="selected.includes(term._id)" label="동의" hide-details @click="toggleSelectedAll(term._id)" />
                                        </v-layout>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <v-card outlined tile>
                            <div class="pa-16px pa-md-20px mh-120px" style="overflow-y: auto;"><div v-html="term.content.replace(/\n/gi, '<br/>')" /></div>
                        </v-card>
                    </template>
                </page-section>
            </template>

            <div class="btn-wrap btn-wrap--sm">
                <v-row class="row--sm">
                    <v-col>
                        <btn-tertiary block x-large @click="cancel">취소</btn-tertiary>
                    </v-col>
                    <v-col>
                        <btn-secondary block x-large @click="agree">동의</btn-secondary>
                    </v-col>
                </v-row>
            </div>
        </div>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";
import UDialog from "@/components/dumb/u-dialog.vue";
// import TitWrapSmall from "@/components/dumb/tit-wrap-small.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";
import BtnTertiary from "@/components/publish/parents/buttons/btn-tertiary.vue";

export default {
    components: {
        UDialog,
        // TitWrapSmall,
        PageSection,

        BtnSecondary,
        BtnTertiary,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        isLoaded: false,
        selected: [],
        termsList: [],

        attrs_input,
    }),
    computed: {
        isValid() {
            return !this.termsList.some(({ _id }) => !this.selected.includes(_id));
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },

        isLoaded(isLoaded) {
            if (isLoaded) this.$emit("isLoaded", this.termsList);
        },
    },
    methods: {
        async init() {
            try {
                this.isLoaded = false;

                let { params } = this;
                var { termsList } = await api.v1.terms.gets({ params });
                this.termsList = termsList;

                this.isLoaded = true;
            } catch (error) {
                this.$handleError(error);
            }
        },

        toggleSelectedAll() {
            if (this.isValid) this.selected = [];
            else this.selected = this.termsList.map(({ _id }) => _id);
        },
        cancel() {
            this.selected = this.value;
            this.shows = false;
        },
        agree() {
            this.$emit("input", this.selected);
            this.$emit("isValid", this.isValid);
            this.shows = false;
        },
    },
};
</script>

<style></style>
